table {
  &.specs {
    //.table-condensed();
    //font-size : @font-size-base*0.8;
    & tr {
      & th {
        text-transform : uppercase;
      }
      & td:first-child {
        font-weight : bold;
        white-space : nowrap;
      }
    }
  }
  &.table-noborder {
    & tr td {
      border:none;
    }
  }
  &.projects {

    //.table-condensed();
    //font-size : @font-size-base*0.8;
    & tr {
      & th {
        text-transform : uppercase;
      }
      & td:first-child {
        font-weight : bold;
        white-space : nowrap;
      }
      & td:last-child {
        width:100%;
      }
    }
  }
  &.table-noborder {
    & tr td {
      border:none;
    }
  }
}