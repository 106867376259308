.video {
  & .list {
    & a {
      display       : block;
      position      : relative;
      margin-bottom : @grid-gutter-width;
      border        : 1px solid @gray-light;
      & img {
        filter : grayscale(50%);
      }
      & div.title {
        position                  : absolute;
        bottom                    : 20px;
        right                     : 0px;
        left                      : 40px;
        line-height               : 1.2;
        background-color          : @brand-primary;
        padding                   : @padding-base-vertical*1.5 @padding-base-horizontal*1.2;
        border-top-left-radius    : 5px;
        border-bottom-left-radius : 5px;
        box-shadow                : -2px 2px 2px rgba(0, 0, 0, 0.60);
        color                     : #fff;
        font-size                 : 13px;
        & small {
          color     : darken(@brand-primary, 20%);
          font-size : @font-size-base*0.8;
        }
      }
    }
    &:hover a {
      & img {
        filter : blur(0px) grayscale(0%);
      }
    }
    &.active a {
      & div.title {
        font-weight : bold;
      }
    }
  }
}

///* Large devices (large desktops, 1200px and up) */
//@media (min-width: @screen-lg-min) {
//
//}
// {

