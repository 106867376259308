/*
/*
* Home page blocks width calculation
*/
.col-md-3-0 {
  .make-md-column(3.0)
}

.col-md-3-4 {
  .make-md-column(3.4)
}

.col-md-4-0 {
  .make-md-column(4.0)
}

.col-md-4-8 {
  .make-md-column(4.8)
}

.col-md-6-0 {
  .make-md-column(6.0)
}

.col-md-8-0 {
  .make-md-column(8.0)
}

.col-md-12-0 {
  .make-md-column(12.0)
}

.col-md-24-0 {
  .make-md-column(24.0)
}

.columns-2 {
  -webkit-column-count : 2;
  -moz-column-count    : 2;
  column-count         : 2;
}

.columns-3 {
  -webkit-column-count : 3;
  -moz-column-count    : 3;
  column-count         : 3;
}

.columns-4 {
  -webkit-column-count : 4;
  -moz-column-count    : 4;
  column-count         : 4;
}

.home {
  &.container, &.container-fluid {

    & h2, & h3, & h4 {
      &:first-child {
        margin-top : 0px;
      }
    }

  }
  &.wrap {
    & h2 {
      margin-bottom : 15px;
    }
    & img {
      display : block;
    }
    //& .branches {
    & a {
      display    : block;
      //visibility:hidden;
      transition : all .2s ease-in;

      &:hover {
        transition:all 0.3s ease-in-out;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.5);
      }
      &.zoom {
        //background-color: @gray-lighter;
        & .text {
          display:none;
        }
        &:hover {
          z-index:999;
          position:absolute;
          transition:all 0.3s ease-in-out;
          box-shadow: 0px 0px 6px rgba(0,0,0,0.5);
          & .text {
            position:relative;
            display:block;
            top:0;
            left:0;
            height:auto;
          }
          & img {
            display:block;
            width:100%;
            height:auto;
          }
        }
      }
    }

    //border          : 1px solid #fff;
    //  transition : all .2s ease-in;
    //  & .well {
    //    & i {
    //      font-size : 200%;
    //    }
    //    & .image {
    //    }
    //    & .text {
    //    }
    //  }
    //  &:hover {
    //    border          : 1px solid @brand-primary;
    //    box-shadow      : 0 0 30px rgba(0, 0, 0, 0.1);
    //    color           : inherit;
    //    text-decoration : none;
    //  }
    //
    //}
  }

  //}
}

table.homepage {
  & tr {
    & td {
      padding : 2px 4px;
    }
  }
}

