.pdf {
  color     : #7b7c7f;
  font-size : 0.8em;
  & h2, & h3, & h4 {
    color          : #0094cb;
    text-transform : uppercase;
    font-weight    : 100 !important;
  }
  & h2 {
    font-size     : 3em;
    margin-bottom : 0px;
  }
  & h3 {
    font-size : 1.3em;
  }
  & h4 {
    font-size   : 1em;
    font-weight : bold;
  }
  & .sidebar {
    //background-color : oldlace;
    & img {
      width  : 100%;
      height : auto;
    }
    & .well {
      display             : block;
      background-color    : #e3eef7;
      //background-image    : url('http://openline_dev.sennik.nl/img/pdf/sidebar_header.svg');
      background-repeat   : no-repeat;
      background-size     : 100% auto;
      background-position : center top;
      margin-top          : 60px;
      padding             : 15px 20px;
      color               : #7997a6;
      & h3 {
        color         : #fff;
        margin-top    : 0px;
        margin-bottom : 60px;
        //background-color : #00a000;
      }
    }
  }

  & .content {
    & h3 {
      color : #0094cb;
    }
    & p {
      margin-bottom : 20px;
    }
    & .credentials {
      color         : #7997a6;
      font-weight   : 300;
      font-style    : italic;
      font-family   : VagRounded;
      margin-bottom : 40px;
    }

    & strong {
      color       : #7997a6;
      font-size   : 1.2em;
      line-height : 1.6;
      font-family : VagRounded;
      font-weight : 300;

    }
  }

}

.pdf-footer {
  //background-color : yellow;
  font-size        : 0.6em;
  //height      : 150px;
  line-height      : 1;
  padding-top      : 30px;
  & strong {
    color       : #0094cb;
    font-weight : normal;
  }
}

@media screen {
  .container.footer {
    display : none;
  }
}

@media print {
  .container.footer {
    position : fixed;
    bottom   : 0;
  }
}

