.calendar {

  &.home {
    & a:not(.btn) {
      display    : block;
      padding    : 15px;
      //border     : 1px solid #fff;
      transition : all .2s ease-in;
      color      : @text-color;
      & .image {
        position : relative;
        & img {
          filter : grayscale(50%);
        }
        & .when, & .where {
          color                     : #fff;
          padding                   : @padding-base-vertical @padding-base-horizontal;
          border-top-left-radius    : 30px;
          border-bottom-left-radius : 30px;
          box-shadow                : -2px 2px 2px rgba(0, 0, 0, 0.60);
        }
        & .when {
          position         : absolute;
          top              : 20px;
          right            : -5px;
          background-color : #fff;
          color            : @brand-primary;
        }
        & .where {
          position         : absolute;
          top              : 65px;
          right            : -5px;
          background-color : @brand-primary;
        }
      }
      & .text {
        & h4 {
          margin : @padding-large-vertical*2 0 @padding-large-vertical;
        }
      }
      &:hover {
       // border          : 1px solid @brand-primary;
        background-color: @gray-lighter;
        //box-shadow      : 0 0 30px rgba(0, 0, 0, 0.1);
        color           : inherit;
        text-decoration : none;
        & img {
          filter : grayscale(0%);
        }
      }
    }
  }

  &.index {

    & a:not(.btn) {
      display    : block;
      padding    : 15px;
      //border     : 1px solid #fff;
      transition : all .2s ease-in;
      & .image {
        position : relative;
        & img {
          filter : grayscale(50%);
        }
        & .when, & .where, & .attending {
          color                     : #fff;
          padding                   : @padding-base-vertical @padding-base-horizontal;
          border-top-left-radius    : 30px;
          border-bottom-left-radius : 30px;
          box-shadow                : -2px 2px 2px rgba(0, 0, 0, 0.60);
        }
        & .when {
          position         : absolute;
          top              : 30px;
          right            : -5px;
          background-color : @brand-secundary;
          color            : #fff;
        }
        & .where {
          position         : absolute;
          top              : 65px;
          right            : -5px;
          background-color : @brand-secundary;
        }
        & .attending {
          position         : absolute;
          top              : 110px;
          right            : -5px;
          background-color : @brand-primary;
        }
        & .title {
          position                   : absolute;
          bottom                     : 30px;
          left                       : -5px;
          font-size                  : @font-size-base*1.2;
          font-weight                : bold;
          background-color           : @gray-darker;
          color                      : #fff;
          padding                    : @padding-base-vertical @padding-base-horizontal;
          border-top-right-radius    : 30px;
          border-bottom-right-radius : 30px;
          box-shadow                 : -2px 2px 2px rgba(0, 0, 0, 0.60);
        }
      }
      & .text {
        & h4 {
          margin : @padding-large-vertical*2 0 @padding-large-vertical;
        }
      }
      &:hover {
        //border          : 1px solid @brand-primary;
        background-color: @gray-lighter;
        //box-shadow      : 0 0 30px rgba(0, 0, 0, 0.1);
        color           : inherit;
        text-decoration : none;
        & img {
          filter : grayscale(0%);
        }
      }
    }
  }

  &.show {
    & .panel.calendar {
      border : none;
      & .list-group {
        & .list-group-item {
          border : none;
          padding:4px 0 4px 0;
          & strong {
            margin-left:10px;
          }
        }
      }
      & li.highlighted {
        color : lighten(@text-color, 20%);
      }
    }

    & .legend {
      //padding-top:15px;
      & div {
        padding : 6px 16px;
        &.title {
          padding     : 12px 8px 0px 16px;
          font-size   : @font-size-base*1.2;
          font-weight : bold;
        }
        &.plakkers {
          padding : 12px 8px 20px 16px;
        }
        & small {
          color : @gray;
        }
      }
    }

  }
}