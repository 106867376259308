.downloads {
  & a {
    display       : block;
    font-size     : @font-size-base*0.9;
    color         : @text-color;
    padding       : 0.3em 0 0.3em 1.3em;
    border-bottom : 1px solid @gray-light;
    &:before {
      font-family : FontAwesome;
      //display     : inline-block;
      position    : absolute;
      left        : 1.8em;
      width       : 1.3em;
      text-align  : center;
      color       : @gray;
    }
    &:after {
      font-family : FontAwesome;
      //display     : inline-block;
      position    : relative;
      left        : 0.3em;
      width       : 1.3em;
      text-align  : center;
      color       : @gray;
    }
    &.pdf {
      &:before {
        content : "\f1c1";
        color   : red;
      }
    }
    &.xls {
      &:before {
        content : "\f1c3";
        color   : #207246;
      }
    }
    &.ppt, &.pptx {
      &:before {
        content : "\f1c4";
        color   : #d04627;
      }
    }
    &.txt {
      &:before {
        content : "\f0f6";
        color   : @gray;
      }
    }
    &.doc, &.docx {
      &:before {
        content : "\f1c2";
        color   : #2d5898;
      }
    }
    &.zip, &.rar {
      &:before {
        content : "\f1c6";
        color   : #982980;
      }
    }
    &.disabled {
      &:after {
        content : "\f127";
      }
    }
    &.error {
      &:after {
        content : "\f127";
        color   : red;
      }
    }
    &:hover {
      color : @brand-primary;
    }
    &:last-child {
      border : none;
    }

  }
}

// In products

.thumbnail {
  &.document {
    position : relative;
    &:before {
      position         : absolute;
      right            : 10px;
      bottom           : 10px;
      font-family      : FontAwesome;
      content          : "\f0f6";
      font-size: @font-size-base*1.2;
      color            : #565656;
      background-color : #fff;
      line-height: 1;
    }
    &.pdf:before {
      content : "\f1c1";
      color   : red;
    }
    &.xls:before, &.xlsx:before {
      content : "\f1c3";
      color   : #207246;
    }
    &.ppt:before, &.pptx:before {
      content : "\f1c4";
      color   : #d24726;
    }
    &.doc:before, &.docx:before {
      content : "\f1c2";
      color   : #2b579a;
    }
  }
  &.software {
    padding : @padding-large-vertical @padding-large-horizontal;
    color   : @text-color;
    &:before {
      font-family : FontAwesome;
      content     : "\f1c6";
      color       : #2d5898;
    }
  }
}