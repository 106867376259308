.social-buttons {
  & a {
    font-size   : @font-size-base*2.5;
    line-height : 1;
    transition : all .2s ease-in-out;
    & span {
      display     : none;
      margin-left : 5px;
      font-size   : @font-size-base;
      line-height : 1;
      color       : @text-color;
    }
    & i {
      color : rgba(0, 0, 0, 0.5);
    }
    &:hover {
      & i.fa-facebook-official {
        color : #49659f;
        transition : all .2s ease-in-out;
      }
      & i.fa-linkedin-square {
        color : #0177b5;
        transition : all .2s ease-in-out;
      }
      & i.fa-twitter-square {
        color : #28a9e0;
        transition : all .2s ease-in-out;
      }
      & i.fa-google-plus-square {
        color : #d34836;
        transition : all .2s ease-in-out;
      }

    }
  }
}
