.navigation {
  z-index  : 1000;
  position : absolute;
  width    : 100%;

  & .topnav {
                     //z-index          : 1000;
    width            : 100%;
    overflow         : visible;
    height           : 40px;
    line-height      : 40px;
    font-size        : @font-size-base*.9;
    background-color : @gray-darker; //@brand-primary;

                     //& form {
                     //  margin : 0;
                     //}

    & ul.list.list-inline {
      margin : 0px;
      & li {
        padding-left  : 8px;
        padding-right : 8px;
        color         : rgba(255, 255, 255, 0.65);
        &.search {
          float : left;
          & form {
            float   : left;
            margin  : 0;
            padding : 7px 0 0 0;
            & input {
                               //float            : left;
              height           : 25px;
              width            : 150px;
              margin           : 0;
              padding          : 2px 4px;
              background-color : @gray-dark; //@brand-primary;
            }
          }
        }
        &.flag {
          height      : Inherit;
          line-height : Inherit;
                      //border-left : 1px solid @gray;
          & a {
            img {
              border     : 1px solid @gray;
              margin-top : -2px;
              height     : 16px;
              width      : auto;
              opacity    : 0.75;
            }
          }
          &:hover {
            & a img {
              opacity : 1;
            }
          }
        }
        &.social {
          border-left : 1px solid lighten(@gray-darker, 10%);
          height      : Inherit;
          line-height : Inherit;
          &:last-child {
            border-right : 1px solid lighten(@gray-darker, 10%);
          }
          & a {
            font-size  : 120%;
            text-align : center;
            & i {
            }
          }
        }
        & a {
          color : rgba(255, 255, 255, 0.75);
        }
        &:hover a {
          color : rgba(255, 255, 255, 1);
                //& i {
                //  color : @brand-primary;
                //}
        }
      }
      &.search {
        margin   : 0px 4px;
        overflow : hidden;
        & .form-control {
          display : inline;
          width   : auto;
        }
      }
    }
  }

           /* Small devices (Smartphones, up to 768px) */
  & nav {
    //z-index : 9999;
    &.navbar {
      font-family      : "Open Sans Condensed", Helvetica, Arial, sans-serif;
      font-size        : 20px;
      font-weight      : 300;
      height           : auto;
      background-color : rgba(255, 255, 255, 0.70); // transparent;
      transition       : all .2s ease-out;
      & .navbar-header {
        height : inherit;
        & button.navbar-toggle {
          position         : absolute;
          padding          : 8px 18px;
          margin           : 16px 15px 0 0;
          color            : @brand-primary;
          background-color : @gray-light;
          border           : none;
          right            : 0px;
          font-size        : 20px;
          &:hover {
            background-color : @brand-primary;
            color            : #fff;
          }
        }
        & .navbar-brand {
          padding : 0px 15px;
          margin  : 0px;
          height  : 100%;
          & img {
            transition : all .2s ease-out;
            box-shadow : 0px 0px 5px rgba(0, 0, 0, 0.5);
            &.shield {
              position   : absolute;
              display    : block;
              margin     : 5px 0px 0px 0px;
              width      : 200px;
              transition : all 0.4s ease;
              &.hide {
                .transition(opacity 0.5s, margin 0.25s;);
                transition : all 0.4s ease;
               }
            }
          }
        }
      }
      & .navbar-collapse {
        & ul.navbar-nav {
          & > li > a {
            text-transform : uppercase;
            padding-left   : 8px;
            padding-right  : 8px;
          }
          & ul.dropdown-menu {
            background-color : #fff;
            font-family      : "Open Sans";
            & > li > a {

              font-size : 16px;
            }
          }
        }
      }
      &.affix {
        transition       : all .2s ease-out;
        position         : fixed;
        .navbar-fixed-top();
        box-shadow       : 0 2px 3px rgba(0, 0, 0, 0.3);
        background-color : #fff; //@gray-darker;
        & .navbar-header {
          & .navbar-brand {
            & img {
              box-shadow : none;
              &.shield {
                position   : absolute;
                display    : block;
                margin     : 5px 0px 0px 0px;
                width      : 225px;
                transition : all 0.4s ease;
                &.hide {
                  .transition(opacity 0.5s, margin 0.25s;);
                  transition : all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }
  }

}

//#nav-mobile {
//  display:none;
//}

/* Small devices (tablets, 768px and up) */
@media (min-width : @screen-sm-min) {
  .navigation {

    & nav {
      &.navbar {
        height : auto;
        & .navbar-header {
          height : auto;
          & .navbar-brand {
            & img {
              transition : all .2s ease-out;
              &.shield {
                margin : -28px 0px 0px 0px;
                width  : 300px;
              }
            }
          }
        }
        & .navbar-collapse {
          & ul.navbar-nav {
            & > li > a {
              padding-left  : 10px;
              padding-right : 10px;
            }
          }
        }
        &.affix {
          //position         : fixed;
          //.navbar-fixed-top();
          & .navbar-header {
            & .navbar-brand {
              & img {
                &.shield {
                  width : 225px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
* Unstyled list
*/
.navmenu {
  position : absolute;
  & ul.navmenu-nav {
    & li {
      & a {
        padding : 4px 16px;
      }
    }
  }
}

/*
* Unstyled list
*/
.products-nav {
  display : block;
  & ul {
    list-style-type : none;
    margin          : 0;
    padding         : 10px;
    & li {
      margin      : 3px 0;
      padding     : 3px 0;
      font-size   : @font-size-base;
      font-weight : normal;
      &.active {
        font-weight : bold;
        &.caret-down {
          &:before {
            font-family : FontAwesome;
            content     : "\f0d7";
            position    : relative;
            left        : -5px;
            color       : @brand-primary;
            text-align  : center;
          }
        }
        &:not(.caret-down) {
          margin-left : 8px;
        }
      }
      &.caret-right {
        &:before {
          font-family : FontAwesome;
          content     : "\f0da";
          position    : relative;
          left        : -5px;
          color       : @text-color;
          text-align  : center;
        }
      }
      &:not(.caret-right) {
        margin-left : 8px;
      }

      & ul {
        list-style-type : none;
        margin          : 5px 0 3px 10px;
        padding         : 0px;
        & li {
          margin      : 0;
          padding     : 3px 0;
          font-size   : @font-size-base;
          font-weight : normal;
          &.active {
            font-weight : bold;
          }
          & ul {
            list-style-type : none;
            margin          : 5px 0 3px 10px;
            padding         : 0;
            & li {
              margin      : 0;
              padding     : 3px 0;
              font-size   : @font-size-base;
              font-weight : normal;
              &.active {
                font-weight : bold;
              }
            }
          }
        }
      }
    }
  }

          //list-style-type : none;
          //margin          : 0;
          //padding         : 3px 0 3px 10px;
          ////border:1px solid red;
          //& li {
          //  margin  : 0;
          //  padding : 3px 0 3px 0px;
          //  &:hover {
          //    color : @brand-primary;
          //  }
          //  &.active {
          //    &.caret-down {
          //      &:before {
          //        font-family      : FontAwesome;
          //        content          : "\f0d7";
          //        position         : relative;
          //        //float            : left;
          //        //width            : 17px;
          //        left: -5px;
          //        color            : @brand-primary;
          //        text-align: center;
          //        //background-color : green;
          //      }
          //    }
          //  }
          //  &.caret-right {
          //    &:before {
          //      font-family      : FontAwesome;
          //      content          : "\f0da";
          //      position         : relative;
          //      //float            : left;
          //      left            : -5px;
          //      color            : @text-color;
          //      text-align: center;
          //      //background-color : green;
          //    }
          //  }
          //}
          //}
  & a {
    color : @text-color;
  }
}

@nav_inset : 20px;

.sidebar {
  padding : 0;
  &.products {
    //border        : none;
    //padding-right : 15px;
    & .level1, & .level2, & .level3, & .level4 {
      .clearfix();
      display     : block;
      margin      : 0;
      line-height : 1.4;
                  //white-space: nowrap;
                  //overflow-x: hidden;
      & a {
        display     : inline-block;
        float       : left;
                    //col
                    //
                    //       : lighten(@text-color, 10%);
        color       : @text-color;
                    //background-color : lime;
        margin      : 0;
        padding     : 3px 4px;
        line-height : inherit;
        &.toggle {
          display          : inline-block;
          float            : left;
          background-color : @gray-lighter;
          margin           : 2px 0;
          padding          : 0;
          width            : @nav_inset;
          text-align       : center;
          & i {
                    //background-color : yellow;
            margin  : 0 0 0 1px;
            padding : 0;
          }
          &:hover {
            background-color : @gray-light;
          }
        }
        &:hover {
                //background-color : @gray-lighter;
          color : @brand-primary;
        }
        &.active {
          font-weight : bold;
          color       : @text-color;
        }
        & span.badge {

          display          : none;
          background-color : transparent;
          border           : 1px solid lighten(@gray, 10%);
          color            : @gray;
        }
        &.item {
          //background-color : yellow;
        }
      }
    }
    & .level1 {
      padding       : 0px 0px 0px 0px;
      border-bottom : 1px solid @gray-lighter;
      &:first-child {
        border-top : 1px solid @gray-lighter;
      }
    }
    & .level2 {
      padding : 0px 0px 0px @nav_inset*1;
              //border-bottom : 1px solid @gray-lighter;
    }
    & .level3 {
      padding : 0px 0px 0px @nav_inset*2;
              //border-bottom : 1px solid @gray-lighter;
    }
    & .level4 {
      padding : 0px 0px 0px @nav_inset*3;
              //border-bottom : 1px solid @gray-lighter;
    }
    //&:before {
    //  font-family : FontAwesome;
    //  //content     : "\f0fe"; //"\f105";
    //  position    : absolute;
    //  left        : 18px;
    //  width       : 10px;
    //  //font-size: @font-size-base*0.9;
    //  text-align  : center;
    //  color       : lighten(@text-color, 25%);
    //}

    //&.level1 {
    //  padding       : 8px 12px; // 8px 28px;
    //  border-bottom : 1px solid @gray-light;
    //  &:first-child {
    //    border-top : 1px solid @gray-light;
    //  }
    //  &.active {
    //    border-bottom : none;
    //  }
    //}
    //&.level2 {
    //  padding       : 4px 12px 4px 28px; // 4px 40px;
    //  border-bottom : none;
    //  //&:before {
    //  //  font-size  : @font-size-base*0.9;
    //  //  margin-top : 1px;
    //  //  left       : 32px;
    //  //}
    //}
    //&.level3 {
    //  padding       : 4px 12px 4px 44px; // 4px 52px;
    //  border-bottom : none;
    //  //&:before {
    //  //  font-size  : @font-size-base*0.9;
    //  //  margin-top : 1px;
    //  //  left       : 44px;
    //  //}
    //}
    //&.level4 {
    //  padding       : 4px 12px 4px 60px; // 4px 62px;
    //  border-bottom : none;
    //  //&:before {
    //  //  font-size  : @font-size-base*0.9;
    //  //  margin-top : 1px;
    //  //  left       : 56px;
    //  //}
    //}
    //
    //&.item {
    //  //&:before {
    //  //  content    : "\f111";
    //  //  font-size  : @font-size-base*0.5;
    //  //  margin-top : 6px;
    //  //}
    //  &.active {
    //    color : darken(@brand-primary, 10%);
    //    //&:before {
    //    //  color : darken(@brand-primary, 10%);
    //    //}
    //  }
    //}
    //}

  }
}

ul.list.list-unstyled {
  & li {
    display : block;
    & a {
      display     : block;
      color       : @text-color;
      font-size   : @font-size-base;
      line-height : 1.25;
      padding     : @padding-base-vertical*1.3 @padding-base-horizontal;
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@text-color, 20%);
      }
      & img {
        filter        : grayscale(50%);
        margin-bottom : 5px;
      }
    }
    &:hover:not(.active) a {
      color            : @brand-primary;
      background-color : @gray-lighter;
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@brand-primary, 20%);
      }
      & img {
        filter : grayscale(0%);
      }
    }
    &.active a {
      background-color : @gray-lighter;
      font-weight      : bold;
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@brand-primary, 20%);
      }
      & img {
        filter : grayscale(0%);
      }
    }
    &.link a {
      border : none;
      &:hover {
        background-color : transparent;
        color            : @brand-primary;
      }
    }
  }
  &.list-striped {
    & li {
      border-bottom : 1px dotted @gray-light;
      &:last-child {
        border-bottom : none;
      }
    }
  }
}

.media {
  & a {
    display : block;
    & .media-left {
      width : 22%;
      & img {
        filter : grayscale(50%);
      }
    }
    & .media-body {
      padding-left : 12px;
      font-size    : @font-size-base*0.9;
      & .media-date {
        font-size : @font-size-base*0.8;
        color     : lighten(@text-color, 20%);
      }
      & .media-heading {
        margin-top  : 4px;
        font-size   : @font-size-base;
        font-weight : 600;
        line-height : 1.2;
        color       : @text-color;
      }
    }
    &:hover {
      background-color : @gray-lighter;
      & img {
        filter : grayscale(0%); //blur(0px)
      }
    }
  }
}

&.nav-tabs {
  & li {
    & a {
      color : lighten(@text-color, 20%);
    }
    &:hover {
      & a {
        color : @text-color;
      }
    }
    &:active, &.active {
      & a {
        color : @text-color;
        & i {
          color : @brand-primary;
        }
      }
    }
  }
}

//#sidebar {
//  &.affix {
//    //position   : fixed;
//    //z-index    : 1003;
//    top   : 0;
//    right : 0;
//    //width      : 100%;
//    //box-shadow : 0 2px 3px rgba(0, 0, 0, 0.3);
//  }
//
//}

