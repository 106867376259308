.swiper-container {
  height  : 400px; // old browsers
  height  : 70vh; // overruled by style
  color   : white;
  //background-color : lighten(@brand-primary, 30%) !important;
  //z-index : 9998;
  & .swiper-slide {
    -webkit-background-size : cover;
    background-size         : cover;
    background-position     : center;
    font-family             : "VAGRounded", Helvetica, Arial, sans-serif;
    font-size               : 4vh;
    & .caption {
      position : absolute;
      right    : 60px;
      bottom   : 30px;
      & .title {
        text-align     : right;
        text-shadow    : @carousel-text-shadow;
        font-weight    : 300;
        text-transform : uppercase;
      }
      & .subtitle {
        text-align  : right;
        text-shadow : @carousel-text-shadow;
        font-weight : 100;
        font-size   : 3vh;
      }
    }
  }
  & .swiper-pagination {
    & .swiper-pagination-bullet {
      background-color : transparent;
      opacity          : 0.5;
      border           : 1px solid #fff;
    }
    & .swiper-pagination-bullet-active {
      background-color : @brand-primary;
      opacity          : 1;
    }
  }

  & .swiper-header {
    display    : none;
    position   : absolute;
    top        : 0px;
    left       : 0px;
    right      : 0px;
    z-index    : 9999;
    min-height : 115px;

    & h1 {
      margin         : 0;
      padding        : 0;
      text-transform : capitalize;
      color          : #fff;
      height         : 30px;
      line-height    : 30px;
    }
    & h2 {
      margin      : 0;
      padding     : 0;
      font-size   : @font-size-h2*0.8;
      color       : #fff;
      height      : 30px;
      line-height : 30px;
    }
  }

  &.inpage {
    height : auto;
    & .swiper-slide {
      background-color : #e3eef7;
      color            : @text-color;
      font-family      : "Lato", Helvetica, Arial, sans-serif;
      font-size        : @font-size-base;
      //padding: @padding*1.5 @padding*2;
    }
  }

}

@media (max-width : @screen-sm-max) {
  .swiper-container {
    & .swiper-slide {
      font-size : 2.2vh;
      & .caption {
        right  : 60px;
        bottom : 30px;
        & .title {
          text-align     : right;
          text-shadow    : @carousel-text-shadow;
          font-weight    : 700;
          text-transform : uppercase;
        }
        & .subtitle {
          text-align  : right;
          text-shadow : @carousel-text-shadow;
          font-size   : 1.8vh;
        }
      }
    }
  }
}

.fullbackground {
  position                : relative;
  -webkit-background-size : cover;
  background-size         : cover;
  background-position     : center;

  & .caption {
    position    : absolute;
    right       : 60px;
    bottom      : 30px;
    color       : #fff;
    font-family : "VagRounded", Helvetica, Arial, sans-serif;
    font-size   : 4vh;
    & .title {
      text-align     : right;
      text-shadow    : @carousel-text-shadow;
      font-weight    : 300;
      text-transform : uppercase;
    }
    & .subtitle {
      text-align  : right;
      text-shadow : @carousel-text-shadow;
      font-weight : 100;
      font-size   : 3vh;
    }
  }

  & .swiper-header {
    display    : none;
    position   : absolute;
    top        : 0px;
    left       : 0px;
    right      : 0px;
    z-index    : 9999;
    min-height : 115px;

    & h1 {
      margin         : 0;
      padding        : 0;
      text-transform : capitalize;
      color          : #fff;
      height         : 30px;
      line-height    : 30px;
    }
    & h2 {
      margin      : 0;
      padding     : 0;
      font-size   : @font-size-h2*0.8;
      color       : #fff;
      height      : 30px;
      line-height : 30px;
    }
  }

}
