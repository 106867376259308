.news {
  //&.index {
  & .featured {
    & a.image {
      display       : block;
      position      : relative;
      margin-bottom : @grid-gutter-width;
      & img {
        filter : grayscale(50%);
      }
      & div.title {
        position         : absolute;
        bottom           : 0px;
        right            : 0px;
        left             : 0px;
        line-height      : 1.2;
        background-color : rgba(0, 0, 0, 0.50); //@brand-primary;
        padding          : @padding-base-vertical*2.5 @padding-base-horizontal*2.2;
        //padding                   : @padding-base-vertical*1.5 @padding-base-horizontal*1.2;
        //border-top-left-radius    : 5px;
        //border-bottom-left-radius : 5px;
        //box-shadow                : -2px 2px 2px rgba(0, 0, 0, 0.60);
        color            : #fff;
        & small {
          color     : lighten(@brand-primary, 20%); //darken(@brand-primary, 20%);
          font-size : @font-size-base*0.8;
        }
        &.main {
          font-size : @font-size-base*1.2;
        }
      }
      &:hover {
        & img {
          filter : blur(0px) grayscale(0%);
        }
        & div.title {
          background-color : rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  & a {
    font-weight : normal;
    color       : @text-color;
    &:hover {
      color           : inherit;
      text-decoration : none;
    }
  }

  /*
  Lists styling: navigation.less
  */

}


//}