.collegas {
  margin : 30px 0;
  & div {
    & *:first-child {
      margin-top : 0;
    }
    & h3 > small {
      font-weight : 100;
      color       : @gray;
    }
    & blockquote {
      background-color : transparent;
      padding:5px 0px;
      & p {
        color : lighten(@text-color, 20%);
      }
    }
  }
}

.awards {

}